/** @jsxImportSource theme-ui */

import { Fragment, useState } from 'react'

import { Box, Flex } from 'theme-ui'
import { stripTimezone } from 'src/utils'

import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'

import { useRequestPDFReport } from 'src/app/hooks/pdfs/useRequestPDFReport'
import CustomSelectInput from '../Forms/CustomSelectInput'
import { validateEmail } from 'src/utils/validations'
import { useToast } from 'src/components/toasts'
import Spinner from 'src/images/icons/Spinner'
import Button from '../Shared/Elements/Button'
import ModalFooter from '../Shared/Elements/ModalFooter'
import InputGroupText from '../Shared/Elements/InputGroupText'

import Icon from '../Shared/Icon'
import { CenteredContent } from '..'
import { DateRangePicker } from '../Forms/DatePickerInput'
import FormError from '../Forms/FormError'
import Label from '../Shared/Elements/Label'
import Input from '../Shared/Elements/Input'

export const metricOptions = ['Average', 'Sum', 'Submission Count']

export const RequestPDFReport = ({
  onClose,
  where,
  incidentId,
  formResponseId,
  formReportMetrics: defaultFormReportMetrics = metricOptions,
  importantSummaryReport: defaultImportantSummaryReport = false,
  ...props
}) => {
  const { add } = useToast()
  const [
    importantSummaryReport,
    setImportantSummaryReport
  ] = useState(defaultImportantSummaryReport)
  const [formReportMetrics, setFormReportMetrics] = useState(
    defaultFormReportMetrics
  )
  const [emailSent, setEmailSent] = useState(false)
  const [loadingRequest, setLoading] = useState(false)
  const { user } = useGetCurrentAgencyContext()
  const [dateRange, setDateRange] = useState({
    startDate: props.startDate,
    endDate: props.endDate
  })
  const [emails, setEmails] = useState([user])

  const [downloadedReports, setDownloadedReports] = useState([])

  const [step, setStep] = useState(
    !props?.startDate && !incidentId && !formResponseId ? 1 : 2
  )
  const reportInput = {
    emails: emails ? emails.map(u => u.email) : [],
    ...props,
    ...(where && {
      where: {
        operator: where.operator,
        ...(where.activities && {
          activities: where.activities.map(a => a.id)
        }),
        ...(where.users && { users: where.users.map(a => a.id) }),
        ...(where.positions && {
          positions: where.positions.map(a => a.id)
        })
      }
    }),

    startDate: stripTimezone(dateRange.startDate),
    endDate: stripTimezone(dateRange.endDate),
    formReportMetrics: formReportMetrics,
    importantSummaryReport: importantSummaryReport,
    ...(incidentId && { incidentId: incidentId }),
    ...(formResponseId && {
      formResponseId: formResponseId
    })
  }

  const { requestReport, error, success } = useRequestPDFReport()

  async function handleGenerate(method) {
    const sendViaEmail = method === 'email'
    if (sendViaEmail && (!emails || !emails.length)) {
      add({
        color: 'danger',
        content: 'Please select at least one user to send to.'
      })
      return
    }

    if (sendViaEmail && emails.some(e => !validateEmail(e.email))) {
      add({
        content: 'Please make sure all emails selected are valid.',
        color: 'danger'
      })
      return
    }

    setLoading(method)

    const response = await requestReport({
      variables: {
        requestInput: {
          ...reportInput,
          sendViaEmail: sendViaEmail
        }
      }
    })

    if (!sendViaEmail) {
      setDownloadedReports(
        response &&
          response.data.requestGeneratedReport &&
          JSON.parse(response.data.requestGeneratedReport.data)
      )
    } else if (!response?.error) {
      setEmailSent(true)
    }
    setStep(3)
    setLoading(false)
  }

  return step === 1 ? (
    <Box>
      <h4>What date range would you like to run this for?</h4>
      <p>
        End date is optional. If you don't select an end date, the
        report will use today.
      </p>

      <Flex
        sx={{
          alignItems: 'flex-start',
          mt: 20,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '10px'
        }}
      >
        <Flex sx={{ gap: '3px' }}>
          {' '}
          <DateRangePicker
            id="range"
            value={dateRange}
            onChange={selected => {
              setDateRange(selected)
            }}
            maxDate={new Date()}
          />
        </Flex>
      </Flex>
      <ModalFooter onClose={onClose}>
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            if (!dateRange?.startDate) {
              return add({
                content: 'Please select a start date.',
                color: 'danger'
              })
            }
            setStep(2)
          }}
          disabled={!dateRange?.startDate}
        >
          Continue
        </Button>
      </ModalFooter>
    </Box>
  ) : (
    <Box>
      <StatOverviewReportSettings
        {...props}
        isIndividualReport={formResponseId || incidentId}
        emailSent={emailSent}
        setEmails={setEmails}
        emails={emails}
        generateReport={handleGenerate}
        loading={loadingRequest}
        formReportMetrics={formReportMetrics}
        setFormReportMetrics={setFormReportMetrics}
        importantSummaryReport={importantSummaryReport}
        setImportantSummaryReport={setImportantSummaryReport}
      />

      {downloadedReports?.length > 0 && (
        <GenerateStatOverviewReport
          reportInput={reportInput}
          loading={loadingRequest}
          success={success}
          downloadedReports={downloadedReports}
        />
      )}
      {error && <FormError error={error} />}
    </Box>
  )
}

const StatOverviewReportSettings = ({
  setEmails,
  emails = [],
  emailSent,
  generateReport,
  loading,
  onClose,
  formReportMetrics,
  setFormReportMetrics,
  importantSummaryReport,
  setImportantSummaryReport,
  isIndividualReport,
  ...props
}) => {
  const isformFieldItemReport = props?.formFieldItems?.length > 0
  const { users } = useGetCurrentAgencyContext()

  return (
    <Flex
      sx={{
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      <h4
        sx={{
          mb: 20
        }}
      >
        Send or Download Report
      </h4>

      <Box
        sx={{
          mt: 20
        }}
      >
        <p>Select teammates or type in a custom email</p>
        <CustomSelectInput
          id="user"
          isMulti={true}
          placeholder="Select users to send to"
          getOptionValue={v => v.id || undefined}
          getOptionLabel={v => v.email || undefined}
          defaultValue={[]}
          value={emails || []}
          creatable
          getNewOptionData={(inputValue, optionLabel) => {
            return {
              id: optionLabel,
              name: inputValue,
              email: inputValue,
              __isNew__: true
            }
          }}
          onChange={selected => {
            setEmails(selected)
          }}
          options={users.filter(u => u.email)}
        />
        {emails && emails.length && (
          <InputGroupText
            style={{ whiteSpace: 'break', textAlign: 'left' }}
          >
            Will send to:{' '}
            {[
              ...(emails && emails.length
                ? emails.map(e => e.email)
                : [])
            ].join(', ')}
            .
          </InputGroupText>
        )}
        {}
        {isformFieldItemReport ? (
          <Box sx={{ mt: '30px' }}>
            <Label>
              Metrics Included (Only applies to number fields)
            </Label>
            {metricOptions.map(m => (
              <Input
                key={m}
                value={m}
                type="checkbox"
                label={m}
                checked={formReportMetrics.includes(m)}
                onChange={e => {
                  const removedAll =
                    formReportMetrics.filter(
                      f => f !== e.target.value
                    )?.length === 0
                  return setFormReportMetrics(
                    removedAll
                      ? metricOptions
                      : formReportMetrics.includes(e.target.value)
                      ? formReportMetrics.filter(
                          f => f !== e.target.value
                        )
                      : [...formReportMetrics, e.target.value]
                  )
                }}
              />
            ))}
          </Box>
        ) : !isIndividualReport ? (
          <Box sx={{ mt: '30px' }}>
            <Input
              type="checkbox"
              style={{ cursor: 'pointer' }}
              id={'importantSummaryReport'}
              name={'importantSummaryReport'}
              checked={importantSummaryReport}
              readOnly
              onClick={e => {
                setImportantSummaryReport(e.target.checked)
              }}
              label={'Important Incident Summary Report'}
            />
          </Box>
        ) : (
          <Fragment />
        )}
      </Box>

      <ModalFooter onClose={onClose}>
        <Button
          variant="secondary"
          type="button"
          disabled={loading}
          onClick={() => generateReport('download')}
          icon={loading === 'download' ? 'spinner' : 'download'}
        >
          {loading === 'download'
            ? 'Generating...'
            : 'Download Report'}
        </Button>
        <Button
          variant={emailSent ? 'success' : 'primary'}
          type="button"
          disabled={loading || !emails || !emails.length}
          onClick={() => generateReport('email')}
          icon={
            emailSent
              ? 'checkmark'
              : loading === 'email'
              ? 'spinner'
              : 'email'
          }
        >
          {emailSent
            ? 'Emails Sent'
            : loading === 'email'
            ? 'Generating...'
            : 'Send Report Emails'}
        </Button>
      </ModalFooter>
    </Flex>
  )
}

const GenerateStatOverviewReport = ({
  loading,
  success,
  downloadedReports
}) => {
  if (loading) {
    return (
      <CenteredContent height="300px">
        <Spinner />
        <p>Gathering the incident data and requesting a report...</p>
      </CenteredContent>
    )
  }

  if (!success) {
    return (
      <CenteredContent height="300px">
        <h4>There was an error generating your report.</h4>
        <p>Please reach out to support if this continues.</p>
      </CenteredContent>
    )
  }

  return (
    <CenteredContent height="300px">
      <Icon icon="success" sx={{ mb: '10px' }} />
      {downloadedReports?.length > 0 && (
        <ReportDownloaded downloadedReports={downloadedReports} />
      )}
    </CenteredContent>
  )
}

const ReportDownloaded = ({ downloadedReports }) => {
  const downloadFile = (fileData, fileName) => {
    const decodedData = atob(fileData)
    const arrayBuffer = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
      arrayBuffer[i] = decodedData.charCodeAt(i)
    }
    const blob = new Blob([arrayBuffer], {
      type: 'application/octet-stream'
    })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
  }

  const handleDownload = file => {
    if (file.attachment) {
      downloadFile(file.attachment, file.filename)
    } else if (file.url) {
      const link = document.createElement('a')
      link.href = file.url
      link.download = file.filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      console.warn('Unknown file format', file)
    }
  }

  return (
    <Flex
      sx={{
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
      }}
    >
      <p>Reports Ready for Download. Click below to download</p>

      {downloadedReports.map((file, index) => (
        <Box key={index}>
          {index === 1 && <h6>Additional Files:</h6>}
          <Button onClick={() => handleDownload(file)}>
            Download {file.filename}
          </Button>
        </Box>
      ))}

      <Box mt={3} sx={{ fontSize: '14px', color: 'gray' }}>
        Note: Any additional files linked here will expire after 15
        minutes for security. If links no longer work, you can
        regenerate the report from the system.
      </Box>
    </Flex>
  )
}
